<template>
  <router-header :routerHeaderInfo="routerHeaderInfo" />

  <a-form ref="formRef"
          :model="form"
          :rules="rules"
          :label-col="{style: 'width: 120px'}">
    <a-form-item label="选择员工">

      <div class="ant-input-affix-wrapper flex justify-between mt-4"
           style="width: 224px;"
           @click.stop="selectStaffModalVisible = true">
        <span v-if="staffNameList.length === 0"
              style="color: #bfbfbf;">全部账号</span>
        <div class="overflow-hidden"
             style="max-width: 160px; max-height: 24px;">
          <template v-for="(staff, index) in staffNameList"
                    :key="index">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>
            <span v-if="index !== staffNameList.length - 1">, </span>
          </template>
        </div>

        <template v-if="staffNum > 0">等{{staffNum}}人</template>
      </div>
    </a-form-item>

    <select-staff-modal v-model:visible="selectStaffModalVisible"
                        :defaultCheckedKeys="checkedKeys"
                        @saveStaff="saveStaff" />

    <a-form-item label="选择客户">
      <a-radio-group v-model:value="form.customerFilterType"
                     @change="hideSendCustomerCnt">
        <a-radio value="all">全部客户</a-radio>
        <a-radio value="filter">筛选客户</a-radio>
      </a-radio-group>

      <div v-show="form.customerFilterType === 'filter'"
           class="p-24 mb-16 bg-color-f5 overflow-y-auto"
           style="max-width: 690px; max-height: 450px">
        <div class="flex">
          <div class="flex-shrink-0 inline-block mr-12 text-right w-max">客户阶段</div>
          <div class="flex-1">
            <a-checkable-tag v-for="stage in customerStagesList"
                             :key="stage.code"
                             v-model:checked="stage.checked"
                             @change="hideSendCustomerCnt">
              {{ stage.name }}
            </a-checkable-tag>
          </div>
        </div>

        <p class="my-16 text-14 text-color-999">若同时选择了客户阶段和标签，将筛选出同时满足客户阶段和标签条件的客户</p>

        <span class="mr-12">企业标签</span>
        <a-radio-group v-model:value="form.tagFilterType"
                       @change="hideSendCustomerCnt">
          <a-radio value="or">以下标签满足其中之一</a-radio>
          <a-radio value="and">以下标签同时满足</a-radio>
        </a-radio-group>

        <div class="flex my-16"
             v-for="tagGroup in cropTagsList"
             :key="tagGroup.workWechatTagGroupId">
          <div class="flex-shrink-0 mr-12 inline-block text-right text-color-999 "
               style="width: 90px;">
            {{ tagGroup.name }}
          </div>
          <div class="flex-1">
            <a-checkable-tag v-model:checked="tag.checked"
                             v-for="tag in tagGroup.corpTagList"
                             :key="tag.workWechatTagId"
                             color="white"
                             @change="hideSendCustomerCnt">
              {{ tag.name }}
            </a-checkable-tag>
          </div>
        </div>
      </div>

      <p class="inline-block py-8 px-12 text-color-999 text-14 bg-admin-primary bg-opacity-10">
        将群发消息给<template v-if="staffShow.length === 0 ">
          全部客户
        </template>
        <template v-else>
          <template v-for="(staff, index) in staffShow.staffList"
                    :key="index">
            「<div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>」
            <template v-if="index === 0 && staffShow.staffList.length === 2">,</template>
          </template>
          <template v-if="staffShow.length > 2">...</template>
        </template>
        的
        <span v-show="form.customerFilterType === 'filter'">客户中符合条件的</span>
        <span v-show="isShowCustomer"
              class="text-admin-primary cursor-pointer"
              @click="getSendCustomerCnt">查看</span>
        <a-spin size="small"
                :spinning="spinning" />
        <span class="text-admin-primary">{{customerCnt.sendCustomerCnt}}</span>
        个客户
      </p>
    </a-form-item>

    <a-form-item class="message-error-position"
                 label="消息"
                 :name="['contents', 0 , 'content']"
                 :rules="rules.message"
                 :wrapper-col="{span: 13}">
      <a-textarea v-model:value="form.contents[0].content"
                  :rows="6"
                  showCount
                  :maxlength="600" />
    </a-form-item>

    <a-form-item label="直播公开课">
      <msg-link class="customer-link"
                :msg="live" />
    </a-form-item>

    <p class="my-16 py-8 px-12 w-max text-14 text-color-999 bg-admin-primary bg-opacity-10 "
       style=" margin-left: 120px;">注意：每位客户每天可接收1条群发消息。</p>

    <a-form-item label="发送时间"
                 :wrapper-col="{span: 13}">
      <a-radio-group v-model:value="form.sendType">
        <a-radio :value="sendTypeEnum.SEND_NOW">立即发送</a-radio>
        <a-radio :value="sendTypeEnum.SEND_TIMED">定时发送</a-radio>
      </a-radio-group>
      <a-form-item v-show="form.sendType === sendTypeEnum.SEND_TIMED"
                   class="inline-block"
                   name="sendDate">
        <a-date-picker v-model:value="form.sendDate"
                       :disabled-date="disabledDate"
                       valueFormat="YYYY-MM-DD"
                       style="width: 180px; margin-right: 16px"
                       @change="handleSendTime" />
      </a-form-item>

      <a-form-item v-show="form.sendType === sendTypeEnum.SEND_TIMED"
                   class="inline-block"
                   name="sendSeconds">
        <a-time-picker v-model:value="form.sendSeconds"
                       valueFormat="HH:mm"
                       format="HH:mm"
                       hideDisabledOptions
                       :minuteStep="5"
                       @change="handleSendTime" />
      </a-form-item>
    </a-form-item>

    <div class="save-btn mx-auto">
      <a-button class="btn"
                type="primary"
                :loading="isSync"
                @click="onSubmit">
        通知成员发送
      </a-button>
    </div>

    <phone-box class="phone-box">
      <li v-show="form.contents[0].content"
          class="reply-list__item">
        <svg-icon class="icon-default-avatar"
                  type="iconmorentouxiang" />
        <span class="msg-text">{{ form.contents[0].content }}</span>
      </li>

      <li class="reply-list__item">
        <svg-icon class="icon-default-avatar"
                  type="iconmorentouxiang" />
        <msg-link :msg="live" />
      </li>
    </phone-box>
  </a-form>

</template>

<script>
import _ from "lodash";
import { h, reactive, ref } from "vue";
import { Radio, Modal, DatePicker, TimePicker, message } from "ant-design-vue";
import dayjs from "dayjs";
import { useRoute, useRouter } from "vue-router";

import RouterHeader from "@/components/RouterHeader.vue";
import selectStaffModal from "@/components/SelectStaffByGroup.vue";
import PhoneBox from "@/components/PhoneBox.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import MsgLink from "@/components/MsgLink";

import CorpTag from "@/service/api/corpLag.js";
import groupSendApi from "@/service/api/groupSend";
import customerStateApi from "@/service/api/CustomerStateApi";
import liveApi from "@/service/api/live";

export default {
  name: "CreateGroupSend",
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ADatePicker: DatePicker,
    ATimePicker: TimePicker,
    RouterHeader,
    selectStaffModal,
    PhoneBox,
    SvgIcon,
    MsgLink,
  },

  setup() {
    // 常量
    const router = new useRouter();
    const route = new useRoute();
    const { roomId, setId } = route.query;

    const routerHeaderInfo = [
      {
        path: "/marketingUtil/liveOpenClassStatistic",
        query: { id: roomId },
        name: "直播详情",
      },
      {
        path: "/marketingUtil/messageShareIndex",
        name: "群发记录",
        query: {
          roomId,
          setId,
        },
      },
      { name: "分享直播" },
    ];

    const sendTypeEnum = {
      SEND_NOW: 0,
      SEND_TIMED: 1,
    };

    const messageTypeEnum = {
      TEXT: 0,
      IMAGE: 1,
      LINK: 2,
    };

    // 判断是否为专栏直播
    if (Number(setId)) {
      _.assign(routerHeaderInfo[0], {
        path: "/marketingUtil/liveColumnStatistic",
        query: {
          setId,
        },
        name: "专栏详情",
      });

      routerHeaderInfo[1].query = {
        roomId,
        setId,
      };
    }

    // 表单数据
    const form = reactive({
      staffIds: [],
      customerFilterType: "all",
      stages: "",

      tagFilterType: "or",
      customerTagIds: "",
      sendType: sendTypeEnum.SEND_NOW,
      sendTime: 0,
      sendDate: null,
      sendSeconds: null,
      roomId: null,
      contents: [
        {
          type: messageTypeEnum.TEXT,
          content: "",
          cover: "",
          link: "",
          summary: "",
          title: "",
        },
      ],
    });

    // 筛选客户数据
    const cropTagsList = ref([]);
    const customerStagesList = ref([]);

    const getCropTagsList = async () => {
      cropTagsList.value = await CorpTag.getList();
    };

    const getCustomerStages = async () => {
      customerStagesList.value = await customerStateApi.findAllStages({
        withNoneStage: true,
      });
    };

    getCropTagsList();
    getCustomerStages();

    const getLiveMsg = async () => {
      const liveMsg = await liveApi.infoRoom({
        roomId,
      });

      if (_.isEmpty(liveMsg)) {
        message.error("未获取到直播间信息，请返回重试");

        setTimeout(() => {
          router.push("/marketingUtil/liveOpenClass");
        }, 1000);
      }

      const { title, liveCover, summary } = liveMsg;

      form.roomId = roomId;
      _.assign(live, {
        title,
        subTitle: summary,
        img: liveCover,
      });
    };

    const live = reactive({});
    getLiveMsg();

    return {
      routerHeaderInfo,
      sendTypeEnum,
      messageTypeEnum,

      form,

      cropTagsList,
      customerStagesList,

      live,
    };
  },

  data() {
    const validateSendTime = (type) => {
      if (this.form.sendType === this.sendTypeEnum.SEND_NOW) {
        return Promise.resolve();
      }

      if (type === "date" && !this.form?.sendDate) {
        return Promise.reject("请选择发送日期");
      }

      if (type === "time" && !this.form?.sendSeconds) {
        return Promise.reject("请选择发送时间");
      }

      return Promise.resolve();
    };

    const rules = {
      message: [
        {
          required: true,
          message: "请输入群发消息",
          trigger: ["change", "blur"],
        },
      ],

      sendDate: [
        {
          validator: validateSendTime.bind(null, "date"),
          trigger: "change",
        },
      ],
      sendSeconds: [
        {
          validator: validateSendTime.bind(null, "time"),
          trigger: "change",
        },
      ],
    };

    return {
      rules,

      selectStaffModalVisible: false,
      staffNameList: [],
      staffNum: 0,
      checkedKeys: [],
      isSync: false,
      customerCnt: { sendCustomerCnt: "" },
      isShowCustomer: true,
      spinning: false,
    };
  },

  computed: {
    staffShow() {
      if (this.staffNameList.length === 0) {
        return { staffList: "全部账号", length: 0 };
      }

      const length = this.staffNameList.length;

      let staffLastTwo = _.take(this.staffNameList, 2);

      return {
        staffList: staffLastTwo,
        length,
      };
    },
  },

  methods: {
    dayjs,

    saveStaff(checkedStaffs) {
      this.selectStaffModalVisible = false;

      this.staffNum = checkedStaffs.length;

      this.checkedKeys = [];
      this.staffNameList = [];
      this.form.staffIds = [];

      _.forEach(checkedStaffs, (staff) => {
        const { workWechatThirdAppUserId, staffId } = staff;
        this.staffNameList.push(workWechatThirdAppUserId);
        this.checkedKeys.push(staffId);

        const splitArr = _.split(staffId, "_");

        this.form.staffIds.push(Number(splitArr[1]));
      });

      this.hideSendCustomerCnt();
    },

    async getSendCustomerCnt() {
      this.isShowCustomer = false;

      this.copyCustomerTagIds();
      this.copyStage();
      this.copyTagFilterType();

      this.spinning = true;
      this.customerCnt = await groupSendApi.getSendCustomerCnt({
        ...this.form,
      });
      this.spinning = false;
    },

    hideSendCustomerCnt() {
      this.isShowCustomer = true;
      this.customerCnt.sendCustomerCnt = "";
    },

    handleSendTime() {
      if (this.form?.sendDate && this.form?.sendSeconds) {
        const time = `${this.form.sendDate} ${this.form.sendSeconds}:00`;
        this.form.sendTime = dayjs(time).valueOf();
      }
    },

    copyStage() {
      let customerStage = _.map(this.customerStagesList, (stage) => {
        return stage.checked ? stage.code : undefined;
      });

      this.form.stages = _.compact(customerStage).join(",");
    },

    copyCustomerTagIds() {
      let newCheckedTagList = [];
      _.forEach(this.cropTagsList, (corpTagList) => {
        _.forEach(corpTagList.corpTagList, (tag) => {
          if (tag.checked) {
            newCheckedTagList.push(tag.workWechatTagId);
          }
        });
      });

      this.form.customerTagIds = _.join(newCheckedTagList, ",");
    },

    copyTagFilterType() {
      this.form.tagFilterType =
        this.form.customerFilterType === "all" ? "" : this.form.tagFilterType;
    },

    async onSubmit() {
      const now = dayjs().valueOf();
      if (this.form.sendTime !== 0 && this.form.sendTime <= now) {
        this.$message.warning("定时发送时间不得小于现在时间");
        return;
      }

      this.$refs.formRef
        .validate()
        .then(async () => {
          Modal.confirm({
            title: "提示",
            content: h("div", [
              h("p", "确定创建群发任务？"),
              h(
                "p",
                { class: "text-14 mt-8 text-color-999" },
                "若客户添加多个员工，系统将自动去重，客户只会收到一次推送。"
              ),
            ]),
            class: "confirm-modal",

            maskClosable: true,
            onOk: async () => {
              this.copyStage();
              this.copyCustomerTagIds();
              this.copyTagFilterType();

              this.isSync = true;
              console.log(this.form);
              await liveApi
                .memberShareLive(this.form)
                .then((res) => {
                  if (res.ok) {
                    this.$message.success("新建成功");
                    this.$router.push({
                      path: "/marketingUtil/messageShareIndex",
                      query: { roomId: this.form.roomId },
                    });
                  } else {
                    this.$message.error("新建失败");
                  }
                })
                .finally(() => {
                  this.isSync = false;
                });
            },
          });
        })
        .catch(() => {
          this.isSync = false;
        });
    },

    disabledDate(current) {
      return current && current < dayjs().subtract(1, "days");
    },
  },
};
</script>

<style lang="less" scoped>
.phone-box {
  position: absolute;
  left: 70%;
  top: 44px;
  transform: scale(0.85);
}

.message-error-position {
  :deep(.ant-form-explain) {
    position: absolute;
    bottom: 8px;
  }
}

:deep(.msg-link.customer-link) {
  align-items: top;
  padding: 12px;
  width: 300px;
  height: fit-content;

  .link-img {
    width: unset;
    height: 64px;
    aspect-ratio: 16 / 9;
  }
}

:deep(.ant-input-affix-wrapper) {
  &::before {
    display: none;
  }
}
</style>